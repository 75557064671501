<template>
  <div class="content profile-content">
    <ProfileNavbar :title="$t('profile.testing.test-title-2')"></ProfileNavbar>
    <div class="mob-menu-title">
      <router-link tag="a" to="/testing" class="mob-menu-back">
        <img src="images/profile/arrow-back.svg">
      </router-link>
      {{ $t('profile.testing.test-title-2') }}
    </div>
    <div class="clearfix bg-blue pt-md-4 pt-lg-0">


      <div class="testing-tab-content tab-content">
        <router-link tag="button" to="/testing" class="btn select-testing-back btn-blue">
          <img src="/images/arrow-back.png" alt="">
        </router-link>
        <div class="testing-lang-wrapper">
          <div class="testing-lang-wrapper-inner">
            <div class="testing-lang-wrapper-text">
              {{ $t('testingLang') }}
            </div>
            <el-radio-group size="medium"
                            v-model="test_lang"
                            @change="update"
                            :placeholder="$t('references.test-language-label')">
              <el-radio-button
                  v-for="item in [{
                                                label: $t('references.language-ru'),
                                                value: 'ru'
                                                }, {
                                                label: $t('references.language-kz'),
                                                value: 'kz'
                                                }]"
                  :key="item.label"
                  :label="item.label"
                  :value="item.value">
              </el-radio-button>
            </el-radio-group>
          </div>
          <img src="/images/Qabilet-Final-logo.svg" alt="" height="54px" style="margin:auto 0">

        </div>
        <div class="online-type profile-cards">
          <div
              class="profile-card my-5 test-card profqabilet-card"
          >
            <div>
              <h2 class="mb-4">{{ $t('profile.testing.test-title-2') }}</h2>
              <div class="test-status">
                <svg
                    class="mr-2"
                    width="15"
                    height="15"
                    viewBox="0 0 15 15"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M7.5 0.625C11.297 0.625 14.375 3.70304 14.375 7.5C14.375 11.297 11.297 14.375 7.5 14.375C3.70304 14.375 0.625 11.297 0.625 7.5C0.625 3.70304 3.70304 0.625 7.5 0.625ZM13.0757 8.24784C12.4131 8.59245 11.5404 8.88061 10.5495 9.07928C10.4087 10.518 10.0755 11.7881 9.59091 12.7236C11.4403 11.9826 12.8047 10.2879 13.0757 8.24784ZM9.36643 8.00277C9.37209 7.83726 9.375 7.66957 9.375 7.5C9.375 4.365 8.379 1.875 7.5 1.875C6.621 1.875 5.625 4.365 5.625 7.5C5.625 7.67932 5.62826 7.85652 5.63457 8.03127C6.22096 8.09258 6.84755 8.12491 7.50031 8.12491C8.14273 8.12491 8.77305 8.07961 9.36643 8.00277ZM10.6226 7.78381C10.6242 7.68965 10.625 7.59504 10.625 7.5C10.625 5.42022 10.2465 3.54206 9.59091 2.27644C11.4511 3.02172 12.8206 4.73185 13.0803 6.78781C12.7859 7.11789 11.8583 7.5099 10.6226 7.78381ZM5.7368 9.29821C6.29759 9.34874 6.88824 9.37491 7.50031 9.37491C8.10228 9.37491 8.69538 9.3408 9.26581 9.27753C8.98389 11.526 8.20438 13.125 7.5 13.125C6.79778 13.125 6.02089 11.5358 5.7368 9.29821ZM4.37847 7.84374C4.01986 7.77219 3.68564 7.68794 3.38047 7.59211C2.60483 7.34853 2.09807 7.04997 1.91608 6.81671C2.16661 4.74789 3.5402 3.02521 5.40909 2.27644C4.75348 3.54206 4.375 5.42022 4.375 7.5C4.375 7.61522 4.37616 7.72982 4.37847 7.84374ZM4.4557 9.13163C4.59945 10.5489 4.9304 11.7995 5.40909 12.7236C3.59165 11.9954 2.24261 10.3462 1.93931 8.35335C2.59146 8.69308 3.45203 8.95714 4.4557 9.13163Z"
                      fill="black"
                  />
                </svg>
                <span v-if="this.testLang === 'ru'"
                >{{ $t('profile.testing.test-language') }}
						{{ $t('profile.testing.russian-lang') }}</span
                >
                <span v-if="this.testLang === 'kz'"
                >{{ $t('profile.testing.test-language') }}
						{{ $t('profile.testing.kazakh-lang') }}</span
                >
              </div>
            </div>
            <div>
              <div class="profile-card-button">
                <el-button
                    @click="profqabilet()"
                    class="btn btn-primary"
                >{{ $t('profile.select-testing.btn-go') }}
                </el-button
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Vue from 'vue';
import ProfileNavbar from "@/components/ProfileNavbar";
import moment from 'moment';
import VueMoment from "vue-moment";

export default {
  name: 'SelectTesting',
  components: {
    ProfileNavbar,
  },
  data() {
    return {
      user_id: 0,
      iin: '',
      testLang: '',
      test_lang: '',
      loading: true,
      hasAvailableQuizzes: false
    }
  },
  methods: {
    update(){
      if (this.test_lang == this.$t('references.language-ru')) {
        this.testLang = 'ru'
      } else if (this.test_lang == this.$t('references.language-kz')) {
        this.testLang = 'kz'
      }
    },
    profqabilet() {
      let url = `${window.PROFQABILET}/?iin=${this.iin}&lang=${this.testLang}`;
      window.open(url, '_blank');
    },
    getLink(){
      this.$http.get(window.API_ROOT + '/api/profqabilet/link')
          .then((res) => {
            this.link = res.data.data.link;
          })
          .catch((e) => {
            console.log(e);
          });
    }
  },
  mounted() {
    this.$http.get(window.API_ROOT + '/api/user/edit')
        .then((res) => {
          this.iin = res.data.data.iin;
        })
        .catch((e) => {

        });
    if (localStorage.getItem('locale')) {
      this.testLang = localStorage.getItem('locale') ?? 'ru'
    }
    if (this.$i18n.locale === 'ru') {
      import('moment/locale/ru')
      Vue.use(VueMoment, {moment});
    }
    if (this.$i18n.locale === 'kz') {
      import('moment/locale/kk')
      Vue.use(VueMoment, {moment});
    }
  }
}
</script>
<style>
.testing-lang-wrapper {
  flex-wrap: wrap;
  display: flex;
  justify-content: space-between;
  margin: 1rem 23px 40px;
  background: #FFFFFF;
  border-radius: 8px;
  padding: 20px;

}

.testing-lang-wrapper-inner {
  max-width: 60%;
}

.testing-lang-wrapper-text {
  margin-bottom: 20px;
}

@media screen and (max-width: 567px) {
  .testing-lang-wrapper-inner {
    display: flex;
    flex-direction: column;
    max-width: 100%;
    margin-bottom: 20px;
  }

  .testing-lang-wrapper img {
    margin: auto !important;
  }

  .testing-lang-wrapper .el-radio-group {
    margin: auto;
  }
}

@media screen and (max-width: 385px) {
  .testing-lang-wrapper {
    padding: 20px 10px;
  }
}
</style>




